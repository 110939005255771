.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    align-self: stretch;
    overflow: hidden;
    position: relative;
    gap: 20px;
}
.titleContainer {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--game-page-padding);
}
.titleContainer .title {
    color: #2664B8;
    font-size: var(--font-midsize);
    font-weight: 800;
    font-family: 'League Spartan';
}

.titleContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 50px;
}

.textWriter {
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: stretch;
    margin-top: 40px;
    margin-bottom: 30px;
}
.inputBox {
    border:2px solid;
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    border-radius: 10px;
    color: #000;
    font-family: 'League Spartan', Inter;
    gap: 4px;
    background: #fff;
    font-size: 28px;
}
.inputBox span {
    padding: 10px 0px;
    font-size: 28px;
}
.inputBox input {
    padding: 10px 0px;
    border: 0px;
    font-size: 28px;
    font-family: 'League Spartan', Inter;
    outline: none!important;
}


.editorContainer {
    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex:1;

}





@keyframes scalePump {
    0% {
        opacity: 0.95;
        transform: scale(0.97);
    }

    100% {
        opacity: 1;
        transform: scale(1.01);
    }
}

.informingError {
    animation: colorAnimate 0.5s alternate-reverse infinite;
}

@keyframes colorAnimate {

    0% {
        --hexcolor: #fcd670;
    }

    100% {
        --hexcolor: #FF160C;
    }

}

.contentGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap:10px;
    width: 60%;
}

.contentGrid .item {
    width:  100%;
    color: #000;
    font-weight: bolder;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 20px;
    font-size: 60px;
    font-weight: bold;
    animation:
            scaleShowingAnimation 1s forwards,
            scalePump 1s alternate-reverse infinite
;
}

.wordIndex {
    font-weight: bold;
    color: #0074d9;
    font-size: 20px;
}


.options {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.options .plus {
    font-size: var(--font-midsize);
    font-weight: bold;
    color: #2664B8;
}

.options .leanOption {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.options .leanOption span {
    font-size: var(--font-midsize);
    font-weight: bold;
    color: #2664B8;
}

.videoPresentation {
    width: auto;
    max-width: 100%;
    height: auto;
    align-self:center;
    max-height: 68vh;
    border: 6px solid #0074d9;
    border-radius: 20px;
    margin:0;
    padding: -2px;

}


.chaosContainer {
    flex: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    align-self: stretch;
    padding: 20px;
}
.chaossContainer {
    flex: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    align-self: center;
    padding: 20px;
    width: 50%;
}
.chaosItem {
    font-size: 30px;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    cursor: pointer;
    transition: 0.1s all;
    font-family: 'League Spartan', Inter;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid #0074d9;
}

.chaosItem.selected {
    background-color: #0074d9;
    color: #fff;
}

.chaosItem.selected:hover {
    background-color: #af4154;
    color: #fff;
}

.chaosItem:not(.selected):hover {
    background-color: #0074d9;
    color: #fff;
}


.chaosItem:active {
    font-size: var(--font-smsize);

}



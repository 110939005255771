@import './_variables';
@import './_mixins';
@import './_animation';

* {
    box-sizing: border-box;
    outline: none;
    --content-padding: 10px;
    --pref-list-flex: row;
    --sound-size: min(calc(50px + 10vw), 200px);
    --word-size: min(calc(50px + 10vw), 140px);
    --word-size-small: min(calc(45px + 12vw), 160px);
    --word-sent-size: min(calc(20px + 8vw), 120px);
    --word-gap: min(calc(10px + 2vw),30px);
    --centered-title-size-xl: min(calc(20px + 2vw), 90px);
    --centered-title-size: min(calc(14px + 1.5vw), 45px);
    --font-midsize: min(calc(14px + 1.5vw), 35px);
    --font-smsize: min(calc(12px + 1.2vw), 25px);
    --right-word-size: min(calc(40px + 7vw), 120px);


    --centered-word-size: min(calc(30px + 6vw), 200px); //TODO: to be updated
    --centered-word-size-xl:  min(calc(60px + 9vw), 500px); //TODO: to be updated

    --game-page-padding: min(calc(5px + 4vh), 60px) min(calc(5px + 4vw), 50px);

    --sound-button-padding: min(calc(8px + 1vw), 15px) min(calc(8px + 3vw), 30px);
    --sound-button-fonts: min(calc(14px + 1.5vw), 45px);

}

html,
body {
    height: 100%;
    overflow-x: hidden;
    font-family: 'League Spartan';
}
* {
    font-family: 'League Spartan';
}

body {
    position: relative;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}


@media (max-width: 750px) {
    * {
        --pref-list-flex: column;
        --centered-title-size-xl: min(calc(18px + 1.5vw), 50px);
    }
}

.defaultErrorPage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #af4154;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 25px;
}

.defaultErrorPage h1 {
    font-size: min(calc(20px + 5vw), 50px);
    color: #fff;
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 20px;
}

.defaultErrorPage h2 {
    font-size: min(calc(10px + 2vw), 35px);
    color: #fff;
    font-weight: normal;
    text-align: center;
    margin-bottom: 60px;
}

.connectivityDialogBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.8);
    z-index: 19000;
    display: flex;
    align-items: center;
    justify-content: center;

}

.connectivityDialog {
    background: #fff;
    width: 90%;
    max-width: 400px;
    border-radius: 20px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.connectivityDialog h1 {
    font-size: var(--centered-title-size);
}
.mainContainer {
  flex: 1;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.sideContainer {
  flex: 1;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;

  /* overflow-y: hidden; */
  /* height: calc(100vh - 110px); */
}

.paragraphHeader {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: transparent;
  padding: 15px 20px;
  color: gray;
  align-items: center;
}

.lexileContainer {
  display: flex;
  flex-direction: column;
}

.lexileContainer .lexileTitle {
  font-size: 16px;
}

.lexileContainer .lexileDetail {
  font-size: 20px;
  font-weight: bold;
  line-height: 100%;
}

.separator {
  height: 90%;
  width: 2px;
  /**
    linear gradient background, from top to center to bottom, top white, center, gray, bottom white
     */
  background: linear-gradient(#fff, #c7c7c7, #fff);
}

.paragraphContainer {
  display: flex;
  flex-direction: column;

  align-self: stretch;
  flex: 1;

  align-self: stretch;
  background: #f5f5f5;
  height: 90vh;
  /*
    box shadow slight to the rignt
     */
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.35);
}

.paragraphContainer p {
  font-size: 28px;
}

.paragraphContainer .sentenceItem {
  /*border: 1px solid gray;*/
  /* margin-left: 18px; */
  line-height: 36px;
  color: #262626;

  /* padding: 4px 0px; */
  border-radius: 5px;
  transition: 0.5s all;
  cursor: pointer;
}

.paragraphContainer .sentenceItem.activeSelection {
  animation: heartbeatAnimation 0.5s infinite;
}

.paragraphContainer:not(:hover):not(.choosen) .sentenceItem.activeSelection {
  /**
--sIndex being from 0...5 for shimmering each item, make sure the animation for each is played consecutively
 */

  animation: shimmerAnimation 4s calc(var(--sIndex) * 2.4s) infinite forwards;
}

@keyframes shimmerAnimation {
  0% {
    background: #ffffff;
  }
  25% {
    background: #c7c7c7;
  }
  100% {
    background: #ffffff;
  }
}

@keyframes heartbeatAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.paragraphContainer .sentenceItem:hover {
  background: #c7c7c7;
  color: #000;
}
.paragraphContainer .sentenceItem.green {
  color: #fff;
  background: #3d9970;
}
.paragraphContainer .sentenceItem.red {
  color: #fff;
  background: #ff4136;
}
.paragraphContainer .sentenceItem.orange {
  color: #fff;
  background: #ff9f1c;
}
.paragraphContainer .sentenceItem.gray {
  color: #fff;
  background: #b5b5b5;
}
.paragraphContainer .sentenceItem.purple {
  color: #fff;
  background: #85144b;
}
.paragraphContainer .sentenceItem.blue {
  color: #fff;
  background: #2f80ed;
}
.questionsContainer {
  display: flex;
  flex-direction: column;

  flex: 1;
  align-self: stretch;
  gap: 30px;

  transition: 0.5s all;
  opacity: 1;

  overflow-y: scroll;
  max-width: 0;

  position: sticky;
  top: 0;
  right: 0;
  
}

.questionsContainer.visible {
  max-width: 100vw;
}

.questionsContainer.hidden {
  max-width: 0;
  opacity: 0;
}

.innerQuestionContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 40px;
  gap: 20px;
}

.headerContainer {
  padding: 10px 25px;
  text-align: center;
}

.selectableQuestionItem {
  align-self: stretch;
  white-space: normal;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}
.qItemWord.blue {
  --select-color: #2f80ed;
}
.qItemWord.green {
  --select-color: #3d9970;
}
.qItemWord.orange {
  --select-color: #ff9f1c;
}
.qItemWord.gray {
  --select-color: #b5b5b5;
}
.qItemWord {
  padding: 0px;
  border: none;
  font-size: 30px;
}
.qItemWord.selectable {
  padding: 4px 6px;
  border: 1px solid gray;
  border-radius: 10px;
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
}
.qItemWord.selected {
  color: var(--select-color);
  font-weight: bold;
}

.qItemWord.selected.incorrect {
  background: #e74c3c;
  font-weight: bold;
  color: #fff;
}

.qItemWord.selected.correct {
  background: #3d9970;
  font-weight: bold;
  color: #fff;
}
.qItemWord.selectable.selected {
  border: 1px solid var(--select-color);
}

.sentenceFillableItem {
  /**
    bottom border 1px black
     */
  border-bottom: 1px solid black;
  display: inline-block;
  position: relative;
  min-width: 150px;
  padding: 5px 10px;
  margin: 0px 5px;
  /*height: 40px;*/
  min-height: 40px;
  min-inline-size: 100px;
}

.sentenceFillableItem.correct {
  color: #3d9970;
}

.sentenceFillableItem.correctMs {
  color: #b69c06;
}

.sentenceFillableItem.incorrect {
  color: #af4154;
}

.sentenceFillableItem:hover .deleteOverlay:not(.hidden) {
  opacity: 90%;
  cursor: pointer;
}

.optionContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: stretch;
}

.optionItem {
  align-self: stretch;
  border: 1px solid gray;
  border-radius: 20px;
  padding: 20px 20px;
  font-size: 24px;
  cursor: pointer;
}
.optionItem.incorrect {
  background: crimson; /*red*/
  color: #fff;
}
.optionItem.correct {
  background: #3d9970; /*red*/
  color: #fff;
}
.optionItem.selected {
  background: #2f80ed;
  color: #fff;
}
.focusWord {
  font-weight: bold;
}

.focusWord.orange {
  color: #ff9f1c;
}

.focusWord.blue {
  color: #2f80ed;
}

.focusWord.green {
  color: #3d9970;
}
.innerContainer {
  padding: 40px;
  height: 100%;
  overflow-y: scroll;
}

.containedParagraph {
}

.draggableWordsContainer {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
}

.deleteOverlay {
  width: 100%;
  height: 100%;

  background: #d65050;
  border: 1px dashed #af4154;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
}
.draggingOverlay {
  width: 100%;
  height: 100%;
  background: #5ac8fa;
  border: 2px dashed #2f80ed;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0%;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.2s all;
}

.draggingOverlay.visible {
  opacity: 40%;
}


.topContainerQ1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
}

.q1ItemList {
  background: #3d9970;
  color: #fff;
  font-size: 25px;
  font-weight: bolder;
  border-radius: 20px;
  padding: 10px;
}

.q2ItemContainer {
  display: flex;
  flex-direction: column;
}
.readPgfInstruction {
  font-size: 22px;
}

.sentenceInstruction {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 400;
}
.sentenceInstructionBlue {
  color: blue;
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 400;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
    align-self: stretch;
    overflow: hidden;
    position: relative;
    --word-color: #000;
    animation: openPageAnimation 1s forwards!important;
}

@keyframes openPageAnimation {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes showImportantStuff {
    0% {
        opacity: 0.2;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}


.container.pass, .container.correct {
    --word-color: #16a085;
}

.container.fail, .container.incorrect  {
    --word-color: #af4154;
}


.container .sequences {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding-bottom: 14vh;
    opacity: 1;
    transition: all 0.5s;
}

.introductionCap {
    animation: showImportantStuff 1s forwards!important;

}


.introductionCap {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2664B8;

    gap: 10px;
    animation: showImportantStuff 1s forwards!important;
}
.introductionCap.hiding {
    animation: hideImportantStuff .5s forwards!important;

}

.introductionCap h1  {
    margin: 0;
    font-size: var(--centered-title-size-xl);
    text-align: center;
    font-family: 'League Spartan';
    font-weight: 900;
}

.introductionCap h2 {
    margin: 0;
    font-size: var(--font-midsize);
    text-align: center;
    font-family: 'League Spartan', Inter;
    font-weight: 800;
    color: #0C8016;
}


.container .sequences.hiding {
    transform: scale(0);
    opacity: 0;
}



.micAndWord {
    color: var(--word-color);
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 20px;
}

.micAndWord  mark {
    background: inherit;
    color: inherit;
}
.container:not(.pass):not(.fail)  .micAndWord  mark{
    color:  #2664B8;

}



.micAndWord h1 {

    font-weight: 800;
    margin:0;
    font-family: 'League Spartan', Inter;
    --multiplier: 1;
    font-size: calc(var(--centered-word-size) * var(--multiplier));
}

.micAndWord h1.large {
    --multiplier: 1;
}
.micAndWord h1.small {
    --multiplier: 1;
}
.micAndWord h1.medium {
    --multiplier: 1.2;
}




.micAndWord.largeSize h1 {
    font-size: calc(var(--centered-word-size-xl) * var(--multiplier));
}


.micAndWord h1:not(.splittedWord) {
    animation: scalePump 1s alternate-reverse infinite;

}
.micAndWord.noAnimation h1:not(.splittedWord){
    animation: unset;
    transform: scale(1);
}

.micAndWord .splittedWord b {
    color: #2664B8;
}

.micAndWord .splittedWord span {
    display: inline-block;
    font-size: calc(var(--centered-word-size) * var(--multiplier));
    font-weight: 800;
    margin:0;

    animation: scalePump 1s alternate-reverse infinite;
    animation-delay: calc(var(--delay) * 0.3s);
    transition: 0.5s;
}




.micAndWord.noAnimation .splittedWord span {

    animation: unset;
    transform: scale(1);
}



.soundButtons  {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: stretch;
    justify-content: center;
}

.soundButtons button {
    display: flex;
    font-size: var(--sound-button-fonts);
    padding: var(--sound-button-padding);
    font-weight: bold;
    font-family: 'League Spartan', Inter;
    border:1px solid #000;
    border-radius: 16px;
    cursor: pointer;
    box-shadow:  0px 2px 2px 2px rgba(0, 0, 0, 0.25);
    transition: 0.5s all;
    color: var(--word-color);
}

.soundButtons button:active{
    transform: scale(0.9);
}




@keyframes scalePump {
    0% {
        opacity: 0.9;
        transform: scale(0.92);
    }

    100% {
        opacity: 1;
        transform: scale(1.01);
    }
}

.informingError {
    animation: colorAnimate 0.5s alternate-reverse infinite;
}

@keyframes colorAnimate {

    0% {
        --hexcolor: #fcd670;
    }

    100% {
        --hexcolor: #FF160C;
    }

}


.titleContainer {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: var(--game-page-padding);
}
.titleContainer .title {
    color: #2664B8;
    font-size: var(--font-midsize);
    font-weight: 800;
    font-family: 'League Spartan';
}



.wordPresentation  {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-self: stretch;
}

.wordColumns {
    display:flex;
    flex-direction: column;
    border: 1px solid #000;
    flex:0.6;
    background:#Fff;
    border-radius: 15px;
    overflow: hidden;
}


.wordItem {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 25px;

    border-bottom: 1px solid #000;
    font-size: 29px;
    font-family: 'League Spartan', Inter;
    box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s all;
}


.wordItem.selected{
    font-weight:bold;
    box-shadow: inset 0px 0px 8px 3px #2664B8;
    color: #2664B8;
}

.wordItem:last-of-type {
    border-bottom:0;
}


.separator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;

    align-self: stretch;

}

.separator .title {
    flex:1.3;
    text-align: center;
    color: #000;
    font-size: var(--right-word-size)
}



/**
Characters styling
 */

.characters {
    flex:1;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--word-gap);

    list-style-type: none;

}
.characters.boxed {
    border: 4px solid var(--word-color);
    padding: 16px 45px;
    border-radius: 25px;
}
.characters .item {
    font-size: var(--word-sent-size);
    font-family: 'League Spartan', Inter;
    font-weight: 800;
    transform: translateY(100vh);
    animation: showingUpFlipper calc(0.3s * var(--delay)) forwards;
    color: var(--word-color);
}

@keyframes showingUpFlipper {
    0% {

    }
    100% {
        transform: translateY(0);
    }
}
@keyframes hidingUpFlipper {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100vh);
    }
}
.characters.hiding .item {

    animation: hidingUpFlipper calc(0.3s * var(--delay)) forwards;
    animation-delay: 1s;
}


.characters.playHit .item {
    animation-name: hitNote;
    animation-duration: 0.7s;
    animation-direction: alternate;
    transform: translateY(0);
    animation-delay: calc(0.8s * var(--delay));
}
@keyframes hitNote {
    0% {
        transform: scale(1.0);
    }
    100% {
        transform: scale(1.2);
    }
}

@keyframes hidingUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100vh);
    }
}
@keyframes showingUp {
    0% {
        transform: translateY(100vh);
    }
    100% {
        transform: translateY(0);
    }
}

.flipperContainer {
    flex: 1;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 10px;
}



/**
imagesText styling
 */

.imageText {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: min(calc(5px + 3vw), 40px);
    color: var(--word-color);
}
.imageText img {
    width: var(--sound-size);
    height: var(--sound-size);
    transform: translateY(100vh);

    animation: showingUp calc(1s) forwards;
    animation-delay: 1s;
}
.imageText span {
    font-size: var(--word-size);
    font-weight: 800;
    margin: 0 !important;;
    padding: 0;
    line-height: 100%;
    transform: scale(0);
    animation: scalingUp calc(1s) forwards;
    animation-delay: 3s;
}


.imageText.hiding img {
    animation: hidingUp calc(1s) forwards;
}

.imageText.hiding span {
    animation: scalingHide calc(1s) forwards;
}

@keyframes scalingUp {
    0% {
        transform: scale(0)
    }
    100% {
        transform: scale(1)
    }
}

@keyframes scalingHide {
    0% {
        transform: scale(1)
    }
    100% {
        transform: scale(0)
    }
}

/**
Images css styling
 */

.imageList {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: var(--pref-list-flex);
    align-items: center;
    justify-content: center;
    gap: min(calc(10px + 5vw), 50px);
}

.imageList img:not(.hiding) {
    width: var(--sound-size);
    height:var(--sound-size);

    animation: showingUp calc(1s * var(--delay)) forwards;
}
.imageList.hiding img {
    animation: hidingUp calc(1s * var(--delay)) forwards;
}


.chaosContainer {
    flex: 1;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 10px;
    align-self: stretch;
    padding: 70px;
}
.chaosItem {
    font-size: 30px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.5s all;
    font-family: 'League Spartan', Inter;
}

.chaosItem:hover {
    font-size: 30px;
    font-weight: bold;
}




.stepsListed {
    display: flex;
    flex-direction: column;
    gap:30px;
    margin-top: 30px;
}

.stepsListed .stepItem {
    display: flex;
    flex-direction: row;
    gap: 30px;
    font-size: 30px;
    font-family: 'League Spartan', Inter;
    animation: stepShowUp 1s forwards;
}

.stepsListed .stepItem .stepNum {
    color: #2664B8;
    font-weight: bold;
}

.stepsListed .stepItem .stepDetails {
    flex:1;
    display: flex;
    flex-direction: column;
}
.stepsListed ul {
    position: relative;
    list-style: none;
}

.stepsListed li{
}
.stepsListed li *{
    display: inline;
}
.stepsListed li::before {
    content: '▶';
    position: absolute;
    left: 0;
}

.stepsListed img {
    max-height: 50px;
    display: inline;
}


.stepsListed center {
    align-self: center;
    font-size: var(--font-midsize);
    color: #EB5757;
    margin: 10px 0px;
    font-weight: bolder;
}

.stepsListed center b {
    color: #2664B8;
    font-size: 50px;
    font-weight: bold;
}

@keyframes stepShowUp {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.stepsListed .stepTitle {

}

.stepsListed .stepTitle * {
    display: inline;
}


.videoPresentation {
    width: auto;
    max-width: 100%;
    height: auto;
    align-self:center;
    max-height: 68vh;
    border: 6px solid #0074d9;
    border-radius: 20px;
    margin:0;
    padding: -2px;
}

.presentList {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;

}

.presentList img {
    min-height: 100px;
}


.wordActions {
    flex: 1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wordActions .word {
    display: flex;
    flex-direction: row;

    font-size: var(--right-word-size);
    font-family: 'League Spartan', Inter;
    font-weight: bolder;
}

.theDropTarget {
    align-self: stretch;
    width: 14px;
    transition: 0.5s all;

}
.theDropTarget:not(.cancelDropTarget).dragging {
    width: 12px;
    background: #2664B8;
    border-radius: 20px;
    margin: 0 15px;
}






.wordActions .word .character{
    position: relative;
    display: flex;
    flex-direction: column;
}

.wordActions .word .character.cancelStage:before {
    position: absolute;
    content: '';
    top: -10%;
    display: block;
    margin: 0 auto;
    background: #EB5757;
    width: 10px;
    height: 110%;
    border-radius: 50px;
    transition: 0.5s all;
    left: 50%;
    transform: translateX(-50%) rotate(5deg);
    opacity: 0;
}



.wordActions .word .character.cancelStage.cancelled:before{
    opacity: 100%;
}

.wordActions .word .character.correctChr {
    color: #219653;
}
.wordActions .word .character.correctChr:after {
    content: '';
    top: 120%;
    display: block;
    margin: 0 auto;
    background: #2664B8;
    width: 20px;
    height: 20px;
    border-radius: 50px;
}
.wordActions .word .character.hoverScalable {
    cursor: pointer;
}
.wordActions .word .character.hoverScalable:hover {

    transform: scale(0.9);
}

.separatorLine {
    padding: 7px 25px;
    cursor: move;
    margin-top: 20px;
}

.separatorLine .sln {
    height:100px;
    width: 14px;
    border-radius: 20px;
    background: #2664B8;
    transition: 0.5s all;
}

.separatorLine.cancellation .sln {
    background: #EB5757;
    transform: rotate(5deg);
}

.separatorLine:active .sln {
    width: 10px;
}


.stageTitle {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2664B8;

    gap: 10px;
    animation: showImportantStuff .5s forwards!important;
}
.stageTitle.hiding {
    animation: hideImportantStuff .5s forwards!important;

}

.stageTitle h1  {
    margin: 0;
    font-size: var(--centered-title-size-xl);
    text-align: center;
    font-family: 'League Spartan', Inter;
    font-weight: 900;
}
.stageTitle h1.small {
    font-size: calc(var(--centered-title-size-xl) * 2);
}

.stageTitle h2 {
    margin: 0;
    font-size: var(--font-midsize);
    text-align: center;
    font-family: 'League Spartan', Inter;
    font-weight: 800;
    color: #0C8016;
}
.stageTitle * {
    text-align: center;
}
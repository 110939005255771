.tableBox {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-radius: 16px;
    border: 2px solid #000;
    overflow: scroll;
    max-height: 75vh;
    align-self: center;

}

.row {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    flex:1;

}

.col {
    border-bottom: 2px solid #000;
    border-right:  2px solid #000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex:1;
    font-size: max(20px, calc(20px + (3vw)));
    min-height: max(20px, calc(20px + (3vw)));
    min-width: max(20px, calc(20px + (3vw)));
    font-family: 'League Spartan', Inter;
    word-break: keep-all;
    white-space: nowrap;



    padding: 10px 25px;


    box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
    transition: 0.5s all;

    margin-bottom: -2px;
    margin-right: -2px;
}

.tableBox.unboxed {
    border: 0;
    gap: 10px;
}

.tableBox.unboxed .col{
    box-shadow: none;
    padding: 2px 10px;
    font-weight: bold;
    border: 0;

}
.col.colored {
    color: #2664B8;
}




.col.selected {
    color: #2664B8;
    font-weight:bold;
    box-shadow: inset 0px 0px 8px 3px #2664B8;
}

.col.small {
    font-size: var(--font-midsize);
    min-height: 90px;
    min-width: 90px;
}

.col.smallest {
    font-size: 30px;
    min-height: 70px;
    min-width: 70px;
}

@media (max-width: 800px) {
    .col {
        min-width: unset;
        min-height: unset;
    }
}
@import url("https://fonts.googleapis.com/css2?family=Alfa+Slab+One&family=Lato:wght@300&display=swap");

.container {
  padding: 0 0rem;
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.container title {
  font-family: "League Spartan";
}

@media (max-width: 501px) {
  .coinsAndButtons {
    width: 35px;
    padding: 10px 5px;
  }
  .rightButtonsContainer {
    bottom: 10px;
  }
}

.homeButton {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  background: #d65050;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;
}
.homeButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

.lessonInformation {
  align-self: stretch;
  background: #2664b8;
  padding: 14px 5px;
  width: 33px;
  animation: showImportantStuff 0.5s forwards !important;
}
@keyframes showLessonInformation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.lessonInformation span {
  display: block;
  writing-mode: vertical-rl;
  color: #fff;
  font-size: 18px;
  font-family: "League Spartan" !important;
}

.lessonInformation span b {
  margin-right: 90px;
  font-family: "League Spartan" !important;
}

.lessonContentContainer {
  flex: 1;
  align-self: stretch;
  overflow-y: scroll;
  display: flex;
  position: relative;
  min-height: 100vh;
  transform: scale(0);
  opacity: 0;
}

.lessonContentContainer:not(.quitting) {
  animation: showLessonContent 0.5s forwards;
}

@keyframes showLessonContent {
  0% {
    border-radius: 100px;
    transform: scale(0);
    opacity: 0;
  }
  100% {
    border-radius: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hideLessonContent {
  0% {
    border-radius: 0;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    border-radius: 100px;
    transform: scale(0);
    opacity: 0;
  }
}

.lessonContentContainer.quitting {
  animation: hideLessonContent 0.5s forwards;
}

.popupBackground {
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 190;
  color: #fff;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
  white-space: nowrap;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  text-align: center;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  grid-template-columns: 1fr 1fr 1fr;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
  width: 100%;
}

.card.disabled {
  opacity: 0.5;
}

.card:not(.disabled):hover,
.card:not(.disabled):focus,
.card:not(.disabled):active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}

.rightButtonsContainer {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.pauseButton {
  width: 70px;
  height: 70px;
  border-radius: 18px;
  background: #f2994a;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;
}
.pauseButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

.infoButton {
  width: 70px;
  height: 70px;
  border-radius: 18px;
  background: #d65050;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;
}
.infoButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

.pausedPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: grayscale(1);
  z-index: 9999;
}

.pausedPage h1 {
  font-size: calc(20px + 5vw);
  font-family: "League Spartan", Inter;
  color: #fff;
  font-weight: bold;
}

.actionContainer {
  z-index: 999;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;

  z-index: 99999;
  width: 200px;
  height: 150px;
  border-radius: 900px 0px 0px 900px;
  margin-top: -50px;
  margin-left: -30px;
  color: #fff;
  padding-top: 50px;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #ffffff;

  transform: translateX(100%);

  transition: 0.5s all;
  opacity: 0;
}

.actionContainer.active {
  opacity: 1;
  transform: translateX(0);
}
.actionContainer .contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-right: 15px;
  padding-top: 15px;
}
.actionContainer.recording {
  background: #d65050;
}
.actionContainer.processing {
  background: #2664b8;
}

.actionContainer.countingDown {
  background: #2664b8;
}
.actionContainer.fail {
  background: #af4154;
}
.actionContainer.pass {
  background: #27ae60;
}

@media (max-width: 900px) {
  .actionContainer {
    width: 150px;
    height: 150px;
    margin-top: -40px;
    margin-left: -10px;
    padding-top: 40px;
  }
  .actionContainer .contentContainer {
    padding: 35px;
    padding-right: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
  }
}

.countDownContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  padding-top: 5px;
  gap: 10px;
}

.numberStyles {
  font-size: 20px;
  font-weight: bold;
}

.countDownContainer h1 {
  margin: 0px;
}

.isButtonDisabled {
  background: #848484 !important;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-self: stretch;
  flex: 1;
  align-items: stretch;
  min-height: 100vh;
}

.stretchedButton {
  align-self: stretch;
  flex: 1;
}

.timerContainer {
  /**
  background color should be fore timer container, suggest better
   */
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  border-radius: 90px;
  color: #fff;
  animation: __scaleSizeUp 0.2s ease-in-out 0s 1 normal forwards;
}
.timerContainer.hiding {
  animation: __scaleSizeDown 0.2s ease-in-out 0s 1 normal forwards;
}
.statusContainer {
  border-radius: 100px;
  width: 170px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;

  color: #fff;
  animation: __scaleSizeUp 0.2s ease-in-out 0s 1 normal forwards;
}

@keyframes __scaleSizeUp {
  100% {
    transform: scale(1);
    opacity: 1;
  }
  0% {
    transform: scale(0);
    opacity: 0.6;
  }
}

@keyframes __scaleSizeDown {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0.6;
  }
}

.statusContainer.processing {
  background: #2664b8;
}

.statusContainer.correct {
  background: #3d9970;
}

.statusContainer.incorrect {
  background: #af4154;
}

.statusContainer.hiding {
  animation: __scaleSizeDown 0.2s ease-in-out 0s 1 normal forwards;
}

.statusContainer h1 {
  margin: 10px !important;
}

.headerContainer {
  text-align: center;
  margin: 0;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}
.progressBar div[role="progressbar"] {
  border-radius: 20px;
}

.titlePage {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  align-self: stretch;
  flex: 1;
  background: #fff;
  /**
  slideinUp once within 1 second
   */
  animation: __slideInUp 1s ease-in-out 0s 1 normal forwards;
  z-index: 90000;
}

@keyframes __slideInUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.titlePage.hiding {
  animation: __slideOutDown 1s ease-in-out 0s 1 normal forwards;
}

@keyframes __slideOutDown {
  from {
    transform: translateY(0);
    filter: opacity(1);
  }
  to {
    transform: translateY(100%);
    filter: opacity(0);
  }
}

.titleContentContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  gap: 20px;
}

.leftSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightSide {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.indentContainer {
  display: flex;
  flex-direction: row;
}
.leftSider {
  flex: 1;
  display: flex;
  /*height:10000px;*/
}
.rightSider {
  width: 200px;
  height: calc(100vh - 110px);
  position: sticky;
  top: 110px;
  overflow-y: hidden;
  background: #f6f6f6;
  border-left: 1px dashed dodgerblue;
  z-index: 1400;
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}

.optionList {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.timerAndStatus {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.submitContainer {
  min-height: 70px;
}

.latoFont {
  font-family: "Lato", sans-serif;
  text-align: center;
  letter-spacing: 10px;
  margin-bottom: 20px;
}

.alfaSlabFont {
  font-family: "Alfa Slab One", cursive;
  text-align: center;
}

.customHr {
  width: 80%; /* adjust width as required */
  border: 0; /* remove default border */
  border-top: 10px solid #1a1a1a; /* apply custom border style */
  margin: 5px auto; /* vertical space and centering */
}

.secondHr {
  width: 80%; /* adjust width as required */
  border: 0; /* remove default border */
  border-top: 10px solid #fab506; /* apply custom border style */
  margin: 20px auto; /* vertical space and centering */
}

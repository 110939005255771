

.area{
    background: var(--hexcolor);
    width: 100%;
    height:100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
   
}

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    transition: opacity 1s;
    opacity: 1;
    margin: 0;
}


.circles li{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    animation: animate 25s linear infinite;
    bottom: -150px;
    transition: background-color .5s;
    font-family: 'League Spartan', Inter;
    font-weight: 800;
    font-size: 100px;

}
.circles.white li {
    color: rgba(255, 255, 255, 0.2);
}

.circles:not(.white) li{
    color: var(--chosen-color);

}

.circles.hiding{
    opacity: 0;
}

.circles.recording li, .circles.processing li,.circles.hiding li  {
    animation-play-state: paused;
}
{
    animation-play-state: paused;
}


.circles.pass li{
    color: rgba(22, 160, 133, 0.2);
}
.circles.fail li{
    color: rgba(175, 65, 84, 0.2);
}




.circles li:nth-child(1){
    left: 25%;
    animation-delay: 0s;
}


.circles li:nth-child(2){
    left: 10%;
    animation-delay: 2s;
    animation-duration: 12s;
}

.circles li:nth-child(3){
    left: 70%;
    animation-delay: 4s;
}

.circles li:nth-child(4){
    left: 40%;
    animation-delay: 0s;
    animation-duration: 18s;
}

.circles li:nth-child(5){
    left: 65%;
    animation-delay: 0s;
}

.circles li:nth-child(6){
    left: 75%;
    animation-delay: 3s;
}

.circles li:nth-child(7){
    left: 35%;
    animation-delay: 7s;
}

.circles li:nth-child(8){
    left: 50%;
    animation-delay: 15s;
    animation-duration: 45s;
}

.circles li:nth-child(9){
    left: 20%;
    animation-delay: 2s;
    animation-duration: 35s;
}

.circles li:nth-child(10){
    left :85%;
    animation-delay :0s;
    animation-duration :11s;
}
/* Added more rules for the remaining items */
.circles li:nth-child(11){
    left :15%;
    animation-delay :5s;
    animation-duration :20s;
}
.circles li:nth-child(12){
    left :30%;
    animation-delay :8s;
    animation-duration :25s;
}
.circles li:nth-child(13){
    left :45%;
    animation-delay :10s;
    animation-duration :30s;
}

.circles li:nth-child(14){
    left :60%;
    animation-delay :22s;
    animation-duration :35s;
}
.circles li:nth-child(15){
    left :80%;
    animation-delay :19s;
    animation-duration :40s;
}
.circles li:nth-child(16){
    left :5%;
    animation-delay :6s;
    animation-duration :45s;
}
.circles li:nth-child(17){
    left :25%;
    animation-delay :15s;
    animation-duration :50s;
}
.circles li:nth-child(18){
    left :55%;
    animation-delay :12s;
    animation-duration :55s;
}
.circles li:nth-child(19){
    left :90%;
    animation-delay :18s;
    animation-duration :60s;
}
.circles li:nth-child(20){
    left: 35%;
    animation-delay: 16s;
    animation-duration: 65s;
}

.circles li:nth-child(21){
    left: 15%;
    animation-delay: 13s;
    animation-duration: 70s;
}

.circles li:nth-child(22){
    left: 75%;
    animation-delay: 24s;
    animation-duration: 75s;
}

.circles li:nth-child(23){
    left: 45%;
    animation-delay: 13s;
    animation-duration: 80s;
}

.circles li:nth-child(24){
    left: 95%;
    animation-delay: 17s;
    animation-duration: 85s;
}
.circles li:nth-child(25){
    left: 65%;
    animation-delay: 19s;
    animation-duration: 90s;
}

.circles li:nth-child(26){
    left: 85%;
    animation-delay: 25s;
    animation-duration: 95s;
}



@keyframes animate {

    0%{
        transform:  translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-1000px) rotate(360deg);
        opacity: 0;

    }

}



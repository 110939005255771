.coinContainer {
    display: flex;
    flex-direction: column;
    flex:1;
    overflow-y: scroll;
    gap: 10px;
    padding: 0px 10px;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 10px;

}



.coinContainer .coin {

}

.slotHolder {
    width: 60px;
    height: 60px;
    background: #D9D9D9;
    border-radius: 90px;
    min-height: 60px;
    perspective: 500px;
    perspective-origin: 50% 0%;
    transform: rotateX(10deg);
}


.slotHolder.coinEarned .coin {

    animation: showingUp calc(1s) forwards;
    transition: 0.5s all;
}


@keyframes showingUp {
    0% {
        transform: translateY(-100vh) rotate(0deg);
    }
    100% {
        transform: translateY(0) rotate(calc(360deg * 9));
    }
}

@media (min-width: 502px) {
    .coinContainer:has(.slotHolder:nth-child(7)) {
        gap: 0px;
    }

    .coinContainer:has(.slotHolder:nth-child(7)) .slotHolder:not(:first-of-type) {
        margin-top: -20px;
        box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.25);
    }

    .coinContainer:has(.slotHolder:nth-child(13)) .slotHolder:not(:first-of-type) {
        margin-top: -40px;
        box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.25);
    }

}


@media (max-width: 501px) {
    .slotHolder {
        width: 25px;
        height: 25px;
        min-height: 25px;
        box-shadow: 0px 0px 0px 0.5px rgba(0, 0, 0, 0.25);

    }
    .slotHolder:first-child{
        margin-top: 1px;
    }
    .slotHolder:not(:first-child) {
        margin-top: -5px;
    }
    .coinContainer .coin {
        width: 25px;
        height: 25px;

    }
    .coinContainer {
        gap: 0px;
    }
}
.background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff; /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.popupBackground {
    background: rgba(0,0,0,0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 190;
    color: #fff;
    padding: 0px 20px;
  }

.popupBackground h1 {
    text-align: center;
}

.popupBackground span {
    text-align: center;
}


.background.hideDown {
    animation: hideImportantStuff 1.3s forwards!important;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 24px 0px;
    align-items: center;
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 40px;
    gap: 35px;
}



.content.absorb {

}
.continueButton {
   
}

.appLogo {
    height: 52vh;
    margin-right: -3.5vw;
    max-width: 90%;
}
.content.absorb .continueButton, .content.absorb .appLogo{
    animation: hideImportantStuff 1s forwards!important;
}





.settingsOption {
}

.settingsOption a {
    cursor: pointer;
 
}

.settingsOption a svg {
    transition: 0.5s all;
}

.settingsOption a:hover svg {
    transform: rotate(60deg);
}

.settingsOption a:active svg {
    transform: rotate(60deg) scale(0.8);
}


.loginContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px;
    gap: 35px;
}


.loginLogo {
    max-width: 200px;
}

.loginForm {
    min-width: 400px;
    padding: 25px 20px;
    border: 4px dashed #0C8016;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 16px;
display: flex;
flex-direction: column;


}

.loginForm * {
}

.loginLogo, .loginForm {
    animation: showImportantStuff 1s forwards!important;
}

@keyframes hideImportantStuff {
    0% {
        opacity: 1;
        transform: translateY(0px)
    }
    100% {
        opacity: 0;
        transform: translateY(calc(100% + 110vh))
    }
}

@keyframes hideThruOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes showImportantStuff {
    0% {
        opacity: 0;
        transform: translateY(120vh)
    }
    100% {
     
        opacity: 1;
        transform: translateY(0px)
    }
}

.hidingButton {
    animation: hideThruOpacity 1s forwards!important;
}

.loginTitle {
    margin:0px;
    font-size: 28px;
}


.loginInput {
    padding: 10px 14px;
    border: 1px solid #000;
    border-radius: 12px;
    margin-top: 12px;
    font-size: 16px;
}

.loginButton {
    margin-top: 24px;
}
.lessonTitlePage {
    display: grid;
    align-self: stretch;
    flex:1;
    padding: 40px;
    padding-left: 0;
}

.lessonTitlePage:not(.examplePresentation).withContent {
    grid-template-areas: "info heading"
                         "info content";
    grid-template-rows: auto 1fr;
    grid-gap: 25px;
    grid-template-columns: 1fr 1fr;
}

.lessonTitle.examplePresentation {
    grid-template-areas: "example example"
                         "example example";
    padding: 40px;
}

.titleInfo {
    grid-area: info;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.headingInfo {
    grid-area: heading;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.contentInfo {
    grid-area: content;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.read21Logo {
    max-width: 30%;
    min-width: 100px;
}

.headingInfo span {
    font-size: 24px;
    text-align: end;
    font-family: 'League Spartan';
}


.lessonTitle, .lessonSubTitle {
    margin: 0px 0;
    font-family: 'League Spartan';
}


.lessonTitle {
    margin-top: 40px;
    font-size: 48px;
    text-align: center;
}
.lessonSubTitle {
    font-size: 32px;
    text-align: center;
    font-weight: normal;
    max-width: 90%;
}

.lessonTitle.examplePresentation > *:not(.exampleBox){
    display: none;
}

@media (max-width: 900px) {
    .lessonTitlePage {
        padding: 20px;
        padding-left: 0;
    }
    .lessonTitlePage:not(.examplePresentation).withContent {
        grid-template-areas: "heading heading"
                         "info info";
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto 1fr;
    }
    .contentInfo {
        display: none;
    }

    .lessonTitle {
        font-size: 38px;
    }
    .lessonSubTitle {
        font-size: 24px;
    }
    .headingInfo span  {
        font-size: 18px;
    }
}
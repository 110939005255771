.floatingLogo {
    position: fixed;
    width: calc(70px + 6vw);
    z-index: 999;
}




.floatingLogo.rt{
  top:20px;
  right:-150px;
  transform: translateX(100%);
  animation: showFromRight 1s forwards,
    scalePump 1s alternate-reverse infinite;
}


@keyframes showFromRight {
    0% {
        opacity: 0;
        right:-150px;
    }
    100% {
        opacity: 1;
       right: 30px;
    }
}

.floatingLogo.lt{
    top:20px;
    left: -150px;    
    animation: showFromLeft 1s forwards,  scalePump 1s alternate-reverse infinite;
}
  
  
@keyframes showFromLeft {
    0% {
        opacity: 0;
        left: -150px;
    }
    100% {
        opacity: 1;
        left: 30px;
    }
}

@keyframes scalePump {
    0% {
        opacity: 0.9;
        transform: scale(0.94);
    }
    100% {
        opacity: 1;
        transform: scale(1.01);
    }
}
.choicePage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: 0px;
    background: #FFFFFF;
    z-index: 90000;

    animation: slideFromBottom 0.2s forwards;


    border: 12px groove #0074d9;

}

@keyframes slideFromBottom {
    0% {
        opacity: 0.5;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}



.choicePage line {
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: center;

}


.fluencyItem, .comprehensionPiece  {
    cursor: pointer;
    flex: 1;
    padding: 0 10%;

    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    justify-content:  flex-end;
    padding-bottom: 30vh;
    /**
    Monochromatic filter
     */
}

.comprehensionPiece  {
    /*filter: grayscale(100%);*/

}
.reviewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: stretch;
    flex: 1;
    padding: 10px 20px;
}

.reviewPage {

}



.reviewTitle {
    font-size: calc(30px + 1.4vw);
    margin: 0;
}

.lessonTitle {
    font-size: calc(18px + 1.2vw);
    text-align: center;
    margin-bottom: 60px;
    font-weight: normal;
}

.soundItem {
    border: 2px solid #BDBDBD;
    padding: 10px 10px;
    width: max(30%, 300px);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap:10px;
}

.soundStatus {
    align-self: stretch;
    width: 36px;
    aspect-ratio: 1;

    padding: 10px;
    border-radius: 5px;
    color: #fff;
}

.soundStatus.success {
    background: #27AE60;
}

.soundStatus.error {
    background: #D65050;
}

.soundItem.listened {
    border: 2px solid #2664B8;
}

.clickableSound {
    font-size: 24px;
    width: fit-content;
    cursor: pointer;
    font-weight: bold;
    padding: 0 10px;
}

.clickableSound:has(.img) {
    display: flex;
    gap: 5px;
    flex-direction: row;
}


.clickableSound img {
    height: 24px;
    width: 24px;
}


.soundItem .clickableSound:after {
    content: '';
    display: block;
    background: #BDBDBD;
    width: calc(100% + 20px);
    height: 2px;
    border-radius: 50px;
    margin-top: 5px;
    margin-left: -10px;
}

.soundItem.listened  .clickableSound:after {
    background: #2664B8;
}

.soundItem .soundButton {
    align-self: stretch;
    width: 36px;
    aspect-ratio: 1;

    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

}

.soundItem .soundButton.playButton {
    background: #BDBDBD;
}

.soundItem .soundButton.recordButton {
    background: #D65050;
    color: #fff;
}


.soundItem.listened  .soundButton.playButton{
    background: #2664B8;
}


.reviewPageFormat {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex:1;
    padding: 20px;
}

.gridList {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    align-self: stretch;
    flex: 1;
    height: 200px;
    padding: 20px;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    overflow-x: scroll;
}

.pageFooter {
    align-self: stretch;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
}

.countDown {
    font-size: 25px;
    margin-top: 10px;
    padding: 10px 30px;
    background: #2664B8;
    color: #fff;
    border-radius: 90px;
}

.recordButton {
    width: 150px;
    height: 150px;
    margin-top: 30px;
    border: 1px solid #000;
    color: #000;
    border-radius: 200px;
    transition: 0.5s all;
    padding: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.recordButton:active {
    transform: scale(0.95);
}
.recordButton.enabled {
    cursor: pointer;
    background: #000;
    color: #fff;
}


.recordButton.recording {
    cursor: pointer;
    color: #fff;
    background: #D65050;
}


.recordButton.processing {
    cursor: pointer;
    color: #fff;
    background: #2664B8;
}


.recordButton.fail, .recordButton.incorrect {
    color: #fff;
    background: #af4154;
}
.recordButton.pass, .recordButton.correct {
    color: #fff;
    background: #27AE60;
}

@media (max-width: 700px) {

    .recordButton {
        width: 100px;
        height: 100px;
        padding: 30px;
    }
}
.mainContainer {
    align-self: stretch;
    flex: 1;
    display: flex;
    flex-direction: column;

}

.headerContainer {
    display: flex;
    flex-direction: column;
}


.contentContainer {
    flex: 1;
    align-self: stretch;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
}


.bookGuy {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    max-height: 100vh;
    transition: 0.5s all;
    padding: 20px 20px;
    flex:1;

}



.clueContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.clueContainer .clueItem {
    padding: 12px;
    background: #0074d9;
    color: #FFFFFF;
    font-size: 24px;
    border-radius: 20px;
    font-weight: bold;
}

.bookGuy .bookGuyImage {
    width: 400px;
    height: 400px;
    object-fit: contain;
}

.bookGuy.questioning {
    max-height: 40vh;
}

.bookGuy.questioning .bookGuyImage {
    width: 300px;
    height: 300px;
    object-fit: contain;
}

.narrationContainer {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: center;
    align-items: center;
}

.sentence {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    border-radius: 20px;
    padding: 30px;
    border: 1px solid #000;
    max-width: 50vw;
}

.sentence.shallSelect {

}

.sentence.narrating {
    opacity: 0.7;
    filter: grayscale(100%);
}


.sentence.shallSelect .wordItem{
    animation: heartbeatAnimation 0.7s alternate-reverse infinite;

}

.wordItem {
    border: 2px solid #1a1a1a;
    padding: 6px;
    border-radius: 10px;
    font-size: 22px;
    cursor: pointer;
   transition: 0.5s all;
}

.wordItem.autoMarked {
    background: #2664B8;
    color: #fff;
    cursor: not-allowed;
}

.wordItem.selected {
    background: #3498db;
    color: #fff;
}

.wordItem.incorrect{
    background: #af4154;
    color: #fff;
}

.wordItem.correct{
    background: #27AE60;
    color: #fff;
}

.sentence.shallSelect .wordItem:active {
    transform: scale(0.9);
}


@keyframes heartbeatAnimation {
    0% {
        transform: scale(1.05);

    }
    100% {
        transform: scale(0.95);
    }
}

.footerContainer {
    flex:1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px;
}

.continueButton {
    position: fixed;
    bottom: 20px;
}
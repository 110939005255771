.alphabetGrid {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  flex: 1;
  align-self: stretch;
  width: 100%;
  gap: 30px;
  overflow: hidden;
}

.sentenceGrid {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex: 1;
  align-self: stretch;
  width: 100%;
  gap: 30px;
  overflow: hidden;
}

.alphabetGrid .item {
  width: 100%;
  color: #2664b8;
  font-weight: bolder;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 70px;
  font-size: 60px;
  font-weight: bold;
}

.shuffledItem {
  width: 100%;
  color: #262626;
  font-weight: bolder;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  min-width: 50px;
  font-size: 30px;
  font-weight: bold;
}

.shuffledItem .splittedWord {
  white-space: nowrap;
  word-break: keep-all;
}
.shuffledItem .splittedWord b {
  color: #2664b8;
}

.shuffledItem .splittedWord span {
  font-weight: 800;
  margin: 0;
}

.alphabetGrid .item:not(.shuffled):last-of-type {
  grid-column: 1 / -1;
}

.exampleBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: scalePump 1s alternate-reverse infinite;
}

.exampleBox h1 {
  color: #2664b8;
  font-size: 30px;
  margin: 0;
  font-family: "League Spartan";
}

.exampleBox span {
  font-size: 160px;
  font-weight: bolder;
  color: #2664b8;
}

.exampleList {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-top: 20px;
}

.exampleList img {
  width: min(calc(30px + 6vw), 90px);
  height: min(calc(30px + 6vw), 90px);
}

.wordPresentation {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-self: stretch;
}

.wordColumns {
  display: flex;
  flex-direction: column;
  border: 1px solid #000;
  flex: 1;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
}

.wordItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px 25px;

  border-bottom: 1px solid #000;
  font-size: 29px;
  font-family: "League Spartan", Inter;
  box-shadow: inset 0px 0px 8px 1px rgba(0, 0, 0, 0.5);
  transition: 0.5s all;
}

.wordItem.selected {
  font-weight: bold;
  box-shadow: inset 0px 0px 8px 3px #2664b8;
}

.wordItem:last-of-type {
  border-bottom: 0;
}

@keyframes scalePump {
  0% {
    opacity: 0.9;
    transform: scale(0.94);
  }
  100% {
    opacity: 1;
    transform: scale(1.01);
  }
}

.gridItem {
  display: grid;
  gap: 30px;
  padding: 40px;
}

.gridItem > *:nth-child(1) {
  grid-area: a1;
  height: min(100%, fit-content);
  width: min(100%, fit-content);
  min-width: fit-content;
}
.gridItem > *:nth-child(2) {
  grid-area: a2;
  height: min(100%, fit-content);
  width: min(100%, fit-content);
  min-width: fit-content;
}
.gridItem > *:nth-child(3) {
  grid-area: a3;
  height: min(100%, fit-content);
  width: min(100%, fit-content);
  min-width: fit-content;
}

.wordList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  gap: 25px;
}

.wordList img {
  max-width: 70%;
}

.wordList .word {
  font-size: 60px;
  font-weight: bold;

  color: #2664b8;
  animation: showingUp calc(1s * var(--delay)) forwards;
}

.wordList .sentence {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #2664b8;
  animation: showingUp calc(1s * var(--delay)) forwards;
}

@keyframes scaleShowingAnimation {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes showingUp {
  0% {
    transform: translateY(100vh);
  }
  100% {
    transform: translateY(0);
  }
}

@media (max-width: 1050px) {
  .alphabetGrid {
    gap: 20px;
  }
  .alphabetGrid .item {
    font-size: 45px;
  }
}

@media (max-width: 800px) {
  .gridItem {
    grid-template-areas:
      "a1"
      "a2"
      "a3" !important;
    padding: 10px;
  }
}

.container {
    height: 100vh;
    width: 100vw;
    position: relative;
    background-image: url(
      "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/graphics/desert.png");
                  background-size: 32px;
                  background-repeat: repeat;
    display: flex;
    flex-direction: column;
}
  
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--sound-button-padding);
  align-items: center;
  background-color: rgba(255,255,255,0.85);
  backdrop-filter: blur(2px);
    position: sticky;
    bottom: 0;
}


.footer .left, .footer .right, .footer .center {
  flex:1;
  display: flex;
  flex-direction: column;
}


.footer .left {
  align-items: flex-start;
}
.footer .center {
  align-items:center;
}
.footer .right {
    flex-direction: row;
  align-items: center;
    justify-content: flex-end;
    gap: 20px;
}


.playButton {
  width: 80px;
  height: 80px;
  border-radius: 18px;
  background: #0053F4;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  animation: heartbeatAnimation 0.7s alternate-reverse infinite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;

}
.playButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

@keyframes heartbeatAnimation {
  0% {
      box-shadow: inset 0px -6px 0px 1px rgba(0, 0, 0, 0.25);
      padding-top: 14px;
      padding-bottom: 16px;
      margin-top: 8px;
  }
  100% {
      box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
      padding-top: 15px;
      padding-bottom: 15px;
      margin-top: 8px;
  }
}

.footerLogo {
  max-height: 75px;
  
}

.footerProfile {
  position: sticky;
  left: 0;
  bottom: 0;
  width: 70px;
  height: 70px;
  background:  #000;
  box-shadow: 0px 0px 0px 3px rgba(0, 0, 0, 0.25);
  border-radius: 18px;
  object-fit:cover;
  cursor: pointer;
  transition: 0.5s;
}

.footerProfile:active {
  transform: scale(0.9);
}

@media only screen and (max-width: 700px) {
  .footerLogo {
    height: 60px;
  }
  .playButton {
    width: 60px;
    height: 60px;
    border: 2px solid #000000;
    border-radius: 8px;
      padding: 30px;

  }

  .playButton  {
      margin-top: 0!important;
  }
  .footerProfile {
    width: 60px;
    height: 60px;
    border-radius: 8px;
    border: 2px solid #717171;
  }
}

.adminRequired {
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(0,0,0,0.9);
    top:0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1532;
}

.adminRequired * {
    font-family: 'League Spartan', Inter;
    color: #fff;
    text-align: center;
}

.adminRequired h1 {
margin-top: 40px;
    font-weight: bold;
}

.adminRequired h2 {
max-width: 33%;
    margin-top: 0;
}
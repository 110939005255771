.background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.3); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
}

.modal {
    background: #fff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    width: 33vw;
    min-width: 400px;
    align-items: center;
    padding: 26px;
    position: relative;
}

.profileImage {
    width: 150px;
    height: 150px;
    border: 6px solid linear-gradient(90deg, #0558F7 0%, #FBA803 100%);;
    border-radius: 140px;

}

.closeIcon {
    position: absolute;
    right: 30px;
    color:#0B0B0B;
    cursor:pointer;
}

.joinDateContainer {
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 16px;
    align-self: stretch;
    background: #EEEEEE;
    color:#000;
    border-radius: 15px;
    align-items: center;


}
.joinDateContainer * {
    font-family: 'League Spartan', Inter;
}

.labelSeparator{
    display: flex;
    flex-direction: column;
}
.labelSeparator h1 {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    /* identical to box height, or 36px */


    color: #000000;
}

.labelSeparator h2 {
    margin: 0;
    font-weight: 400;
    font-size:18px;
    line-height: 100%;
    /* identical to box height, or 20px */


    color: #000000;

}

.lessonButton, .logoutButton{
    align-self: stretch;
    margin-top: 15px;

}
.lessonButton span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.logoutButton{
    margin-top: 35px;
}

.settingsContent {

    margin-top: 20px;
    margin-bottom: 20px;
}

.settingsContent select {
    padding: 5px 10px;
    font-size: 18px;
    min-width: unset;
    width: 100%;
}




.settingsContent label {
    display: block;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 20px;
}


.soundTrack {
    border-radius: 20px;
    background: #8f94fb;
    height: 8px
}

.volumeBall {
    width: 30px;
    height: 30px;
    background: #2664B8;
    border-radius: 90px;
    cursor: pointer;
    transition: 0.2s all;
}

.volumeBall:hover {
    transform: scale(1.1);
}

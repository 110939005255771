.plusContainer {
    /*position: "fixed", top: 0, left: 0, width: "100%", height: "100%", background:"rgba(0,0,0,0.8)", display:"flex",*/
    /*flexDirection:"column",*/
    /*zIndex: 100000,*/
    /*alignItems:"center",*/
    /*justifyContent:"center"*/

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background: rgba(0,0,0,0.8);
    display: flex;
    flex-direction: column;
    z-index: 100000;
    align-items: center;
    justify-content: center;



}

.plusTrainerDialog {

/*{{width: 400, height:400, background:"#fff", padding: 30, borderRadius: 20, display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center"}}*/
    width: 100px;
    height: 100px;
    background: #fff;
    padding: 30px;
    border-radius: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    animation: bounce 1s forwards, transformToDialog 1s 1s forwards;
}

.plusTrainerDialog::before {
    content: "";
    position: absolute;
    bottom: -20px;
    left: 50px;

    width: 40px;
    height: 40px;
    background-color: #3498db;
    border-radius: 50%;
}

.plusTrainerDialog::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 10px;

    width: 30px;
    height: 30px;
    background-color: #3498db;
    border-radius: 50%;
}



@keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {
        transform: translateY(0);
    }
    10%, 30%, 50% {
        transform: translateY(-30px);
    }
    70% {
        transform: translateY(-20px);
    }
    90% {
        transform: translateY(-10px);
    }
}

@keyframes transformToDialog {
    0% {
        width: 100px;
        height: 100px;
        border-radius: 90px;
        background-color: #3498db;
    }
    100% {
        width: 500px;
        height: 300px;
        border-radius: 100px 100px 100px 40px;
        background-color: #fff;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
        border: 1px solid #ddd;
        transform: translateY(0);
    }
}

.plusTrainerDialog .dialogContent {
    /**
    animation that starts after 6 seconds that makes the dialog content appear
     */
    opacity: 0;
    animation: appear 1s 2s forwards;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


.syllableList {
    display: flex;
    flex-direction: row;
    gap: 10px;
    filter: blur(10px);
    transition: 0.5s all;
}

.syllableList.begun {
    filter: blur(0px);
}

.statusContainer {
    position: absolute;
    top: calc(110% + 10px);

    opacity: 0;
    transition: 0.5s all;
}
.statusContainer.visible {
    opacity: 1;
}

.itemContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 5px;
    transition: 0.5s all;
}

.itemContainer.noGap {
    gap:0px
}

.item {

    padding: 0px 10px;
    border-radius: 10px;
    transition: 0.5s all;
}

.itemContainer.noGap .item {
    padding: 0px;
}


.item.recording, .item.processing, .item.playing {
    background-color: #3498db;
    color: #fff;
}

.item.correct {
    background-color: #2ecc71;
    color: #fff;
}

.item.wrong {
    background-color: #e74c3c;
    color: #fff;
}

.item.marked {
    border-bottom: 3px solid #3498db;
}


.itemText{
    font-size: 40px;
    font-family: 'League Spartan', Inter;
    font-weight: bold;
    line-height: 120%;
}

.syllableSeparator {
    width: 15px;
    height: 15px;
    border-radius: 100px;
    background: #858585;
    align-self: center;
    transition: 1s all ease-in-out;
}

.syllableSeparator.hidden{
    opacity: 0;
    width: 0px;
    height: 0px;
}
.container {
  padding: 0 0rem;
  position: relative;
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.container title {
  font-family: 'League Spartan';
}

.coinsAndButtons {
  display: flex;
  flex-direction: column;
  width: 80px;
  padding: 20px 0px;
  background: #fff;
  align-items: center;
  box-shadow: inset -2px 0px 1px rgba(0, 0, 0, 0.10);
}

@media (max-width: 501px) {
  .coinsAndButtons {
    width: 35px;
    padding: 10px 5px;
  }
  .rightButtonsContainer {
    bottom: 10px;
  }
}

.coinsAndButtons .homeButton  {
  width: 70px;
  height: 70px;
  border-radius: 80px;
  background: #af4154;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 2px solid #000;
  padding: 15px 20px;
  cursor: pointer;
}


.lessonInformation {
  align-self: stretch;
  background: #2664B8;
  padding: 14px 5px;
  width: 33px;
  animation: showImportantStuff 0.5s forwards!important;

}
@keyframes showLessonInformation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}



.lessonInformation span {
  display: block;
  writing-mode:vertical-rl;
  color: #fff;
  font-size: 18px;
  font-family: 'League Spartan'!important;
}

.lessonInformation span b{
  margin-right: 90px;
  font-family: 'League Spartan'!important;
}

.lessonContentContainer {
  flex:1;
  align-self: stretch;
  overflow-y: scroll;
  display: flex;
  position: relative;
  min-height: 100vh;
  transform: scale(0);
  opacity: 0;
}

.lessonContentContainer:not(.quitting){
  animation: showLessonContent 0.5s forwards;

}

@keyframes showLessonContent {
  0% {
    border-radius: 100px;
    transform: scale(0);
    opacity: 0;
  }
  100% {
    border-radius: 0;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes hideLessonContent {
  0% {
    border-radius: 0;
    transform: scale(1);
    opacity: 1;
  }
  100% {
    border-radius: 100px;
    transform: scale(0);
    opacity: 0;
  }
}

.lessonContentContainer.quitting {
  animation: hideLessonContent 0.5s forwards;


}

.popupBackground {
  background: rgba(0,0,0,0.9);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 190;
  color: #fff;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
}

.title,
.description {
  text-align: center;
}

.description {
  margin: 4rem 0;
  line-height: 1.5;
  font-size: 1.5rem;
}

.code {
  background: #fafafa;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
    Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1000px;
  grid-template-columns: 1fr 1fr 1fr;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;
  width: 100%;
}

.card.disabled {
  opacity: 0.5;
}

.card:not(.disabled):hover,
.card:not(.disabled):focus,
.card:not(.disabled):active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem 0;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;
}

@media (max-width: 600px) {
  .grid {
    width: 100%;
    flex-direction: column;
  }
}

@media (prefers-color-scheme: dark) {
  .card,
  .footer {
    border-color: #222;
  }
  .code {
    background: #111;
  }
  .logo img {
    filter: invert(1);
  }
}


.rightButtonsContainer {
  position: fixed;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  gap: 10px;

}



.pauseButton {
  width: 70px;
  height: 70px;
  border-radius: 18px;
  background: #F2994A;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;
}
.pauseButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

.infoButton {
  width: 70px;
  height: 70px;
  border-radius: 18px;
  background: #D65050;
  box-shadow: inset 0px -8px 0px 1px rgba(0, 0, 0, 0.4);
  border: 4px solid #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  cursor: pointer;
}
.infoButton:active {
  animation: none;
  box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}



.pausedPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.7);
  z-index: 9999;
}

.pausedPage h1 {
  font-size: calc(20px + 5vw);
  font-family: 'League Spartan', Inter;
  color: #fff;
  font-weight: bold;
}

.actionContainer {
  z-index: 999;
  display: flex;
  position: fixed;
  top: 0;
  right: 0;

  z-index:99999;
  width: 200px;
  height: 150px;
  border-radius: 900px 0px 0px 900px;
  margin-top: -50px;
  margin-left: -30px;
  color: #fff;
  padding-top: 50px;
  border-width: 0px 0px 4px 4px;
  border-style: solid;
  border-color: #FFFFFF;

  transform: translateX(100%);

  transition: 0.5s all;
  opacity: 0;


}

.actionContainer.active {
  opacity: 1;
  transform: translateX(0);
}
.actionContainer .contentContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 30px;
  padding-right: 15px;
  padding-top: 15px;
}
.actionContainer.recording {
  background: #D65050;
}
.actionContainer.processing {
  background: #2664B8;
}

.actionContainer.countingDown {
  background: #2664B8;
}
.actionContainer.fail {
  background: #af4154;
}
.actionContainer.pass {
  background: #27AE60;
}



@media (max-width: 900px) {
  .actionContainer {
    width: 150px;
    height: 150px;
    margin-top: -40px;
    margin-left: -10px;
    padding-top: 40px;
  }
  .actionContainer .contentContainer {

    padding: 35px;
    padding-right: 20px;
    padding-top: 20px;
    margin-bottom: 10px;
  }
}



.countDownContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  flex: 1;
  padding-top: 5px;
  gap:10px;
}

.numberStyles {
  font-size: 20px;
}



.countDownContainer h1 {
  margin: 0px;
}

.isButtonDisabled {
  background: #848484!important;
}
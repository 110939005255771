.screenContainer {
    width: 100vw;
    flex:1;
    display: flex;
    flex-direction: column;
    background-image: url(
        "https://d3vx0tne0vxeo1.cloudfront.net/assets/images/graphics/desert.png");
                    background-size: 32px;
                    background-repeat: repeat;
}
.mapCanvas {
    display: flex;
    flex:1;
    align-self: stretch;
    overflow: hidden;
    height: 90vh;
}

.mapCanvas canvas {
    flex:1;
    align-self: stretch;
    display: block;
    margin: auto;
}

.options {
    display: flex;
    align-self: stretch;
    justify-content: space-between;
    padding: 20px 20px;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(4px);
}

.options button {
    background-color: black;
    border-radius: 15px;
    border: 0;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    padding: 10px 20px;
}
@font-face {
    font-family: 'KG Primary Penmanship';
    src: url('../fonts/KGPrimaryPenmanship.ttf');
}

.button {
    position: relative;
box-shadow: inset 0px -6px 0px 1px rgba(0, 0, 0, 0.25);

font-family: 'KG Primary Penmanship';
font-weight: 700;
font-size: 24px;
line-height: 100%;
/* identical to box height, or 48px */
cursor: pointer;

color: #FFFFFF;
transition: 0.1s all;
display: flex;
flex-direction: row;
align-items: center;
gap: 20px;
    overflow: hidden;

}

.button.fillWidth {
    flex:1;
}

.button.rounding_full {
    animation: showAnimationwScale 0.5s forwards;
}

.button.rounding_left {
    animation: showAnimationFromLeft 0.5s forwards;
}

.button.rounding_right {
    animation: showAnimationFromRight 0.5s forwards;
}

@keyframes showAnimationwScale {
    0% {
        scale: 0.5;
        opacity: 0;
    }
    100% {
        scale: 1;
        opacity: 1;
    }
}
@keyframes showAnimationFromRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}


@keyframes showAnimationFromLeft {
    0% {
      transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}

.inButtonLoadingContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 0px;
}


.button svg {
    color: #FFFFFF;
}

.button.content_large {
    font-size: 24px;
    --border-width: 4px;
    --border-radius: 18px;
}

.button.content_medium {
    font-size: 20px;
    --border-width: 2px;
    --border-radius: 12px;
}
.button.content_small {
    font-size: 16px;
    --border-width: 2px;
    --border-radius: 12px;
}
.button.pv_full {
    padding-bottom: var(--border-radius);
    padding-top: var(--border-radius);

}

.button.pv_medium {
    padding-bottom: 12px;
    padding-top: 12px;
}

.button.ph_full {
    padding-left: 20px;
    padding-right: 20px;
}
.button.ph_medium {
    padding-left: 12px;
    padding-right: 12px;
}
.button.blue {
    background: #0053F4!important;;
}

.button.green {
    background: #0C8016!important;;
}
.button.gray {

    background: #676767!important;;
    color: #000;
}
.button.grey {
    /**
    subtle background and color
     */
    background: #F4F4F4!important;
    color: #676767;
}

.button.grey svg {
    color: #676767;
}
.button.gray svg {
    color: #000
}

.button.red {
    background: #af4154!important;;
}

.button.rounding_full {
    border-radius: var(--border-radius);
}


.button.animation_none {

}


.button.animation_beat {
    animation: heartbeatAnimation 0.7s alternate-reverse infinite;
}


@keyframes heartbeatAnimation {
    0% {


    }
    100% {
        box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
        padding-top: 20px;
        padding-bottom: 15px;

    }
}






.button.border_black {
    border: var(--border-width) solid #000000;
}

.button.border_white {
    border: var(--border-width) solid #fff;
}

.button.border_none {
    border: 0px solid #fff;
}


.button.rounding_right {
    border-radius: 0px var(--border-radius) var(--border-radius) 0px;
    border-width: var(--border-width) var(--border-width) var(--border-width) 0px;
    border-style: solid;
    border-color: #000000;
}

.button.rounding_left {
    border-radius: var(--border-radius) 0px 0px var(--border-radius);
    border-width: var(--border-width) 0px var(--border-width) var(--border-width);
    border-style: solid;
    border-color: #000000;
}

.button.click_scale:focus {
    animation: none;
    transform: scale(0.90);
}

.button.click_scale:active {
    animation: none;
    box-shadow: inset 0px -1px 0px 1px rgba(0, 0, 0, 0.25);
}

.button svg {
    transition: 0.5s all;
}
.button.click_scale:hover svg {
    transform: translateX(5px);
   
}

.button.click_scale:active svg {
    transform: translateX(0px);
}

.button.click_inside:hover svg {
    transform: scale(0.8)
}



.button span {
    flex:1;
    text-align: start;
}
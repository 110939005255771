.background {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding: 20px 20px;
  z-index: 999999;
}

.modal {
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  width: 12vw;
  align-items: center;
  position: relative;
}

.closeIcon {
  position: absolute;
  right: 30px;
  color: #0b0b0b;
  cursor: pointer;
}

.settingsContent {
  margin-top: 80px;
  margin-bottom: 80px;
  animation: alternate 1s infinite heartbeatAnimation;
}


/**
heartbeat animation
 */
@keyframes heartbeatAnimation {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1.1);
  }
}
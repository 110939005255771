.modalContainer {
    display: flex;
    position: absolute;
    background: rgba(0,0,0,0.6);
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

}

.modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.modal video {
    max-width: 90%;
    max-height: 70%;
}

.modal .closeButton {
    top: 20px;
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 15px;
    border-radius: 20px;
    background: #000;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
.introductoryPage {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
    background: #FFFFFF;
    z-index: 90000;
}
.introductoryPage .imgAndTitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex:1;
    max-height: 80%;
}

.introductoryPage .imgAndTitle video {
    width: 100%;
    height: 100%;
    box-shadow: inset 0 10px #0074d9;
    border-radius: 20px;
    margin:0;

}

.bottomContainer {
    height: fit-content;
    margin-bottom: 10px;
    margin-top: 10px;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: flex-end;
    padding: 20px 20px;
    align-items: center;
    justify-content: center;
}



.stageIntroductionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
    align-self: stretch;
    overflow: hidden;
    position: relative;
}

@keyframes hideImportantStuff {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {


        opacity: 0;
        transform: scale(0.5);
    }
}
@keyframes showImportantStuff {
    0% {
        opacity: 0.2;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.stageTitle {
    flex: 1;

    align-self: stretch;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2664B8;

    gap: 10px;
    animation: showImportantStuff 1s forwards!important;
}
.stageTitle.hiding {
    animation: hideImportantStuff .5s forwards!important;

}

.stageTitle h1  {
    margin: 0;
    font-size: var(--centered-title-size-xl);
    text-align: center;
    font-family: 'League Spartan';
    font-weight: 900;
}

.stageTitle h1.small {
    font-size: calc(var(--centered-title-size-xl) * 2);
}

.stageTitle h2 {
    margin: 0;
    font-size: var(--font-midsize);
    text-align: center;
    font-family: 'League Spartan', Inter;
    font-weight: 800;
    color: #0C8016;
}
.stageTitle * {
    text-align: center;
}
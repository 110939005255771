.background {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.3); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 999;
}
.modalContainer {
    background: #fff;
    border-radius: 20px;
    width: clamp(300px,50vw, 700px);
    min-width: 300px;
    align-items: center;
    padding: 26px;
    position: relative;
    height: 90vh;
    overflow-y: scroll;
    padding: 20px 0px;
}

.modal {
  
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
 
    position: relative;
    padding: 0px 20px;
    height: 100%;

}

.reportTitleBox {

}


.columnGaper {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-self: stretch;
    gap: 30px;
}

.barContainer {
    flex:1;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 20px;
}
.barItem {
    flex: 1;
    align-self: stretch;
}

.table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px #666; /* this draws the table border  */
}

.table td {
    border: 1px solid black;
    padding: 10px;
    align-items: center;
    justify-content: center;
    text-align: center;
    align-content: center;

}

.table td a {
    color: blue;
    text-decoration: none;
    font-size: 18px;
}


.lessonCircles {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   gap: 5px;
   justify-content: space-between;
}
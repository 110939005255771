.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  gap: 20px;
}

@keyframes animate {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
  }

  100% {
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
  }
}

.titleContainer {
  flex: 1;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: var(--game-page-padding);
  justify-content: center;
}
.titleContainer .title {
  color: #2664b8;
  font-size: var(--font-midsize);
  font-weight: 800;
  font-family: "League Spartan";
}

.titleContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px 50px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  position: relative;
  justify-content: center;
}

.sequences {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

@keyframes scalePump {
  0% {
    opacity: 0.95;
    transform: scale(0.97);
  }

  100% {
    opacity: 1;
    transform: scale(1.01);
  }
}

.informingError {
  animation: colorAnimate 0.5s alternate-reverse infinite;
}

@keyframes colorAnimate {
  0% {
    --hexcolor: #fcd670;
  }

  100% {
    --hexcolor: #ff160c;
  }
}

.sequences {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-family: "League Spartan", Inter;
  gap: min(calc(5px + 1vw), 10px);
  opacity: 0.4;
  transition: 0.5s;
}
.sequences.active {
  opacity: 1;
}

.sequences .word {
  padding: 6px min(calc(4px + 1vw), 10px);
  /* border: 1px solid gray;
  border-radius: min(calc(4px + 1vw), 10px); */
  font-size: min(calc(12px + 1.5vw), 25px);
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
  transition: 0.2s ease-in-out all;
}

.sentences {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: min(calc(10px + 2vw), 25px);
}

.rioContainer {
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

@media screen and (min-width: 768px) {
  .rioContainer.large {
    flex-direction: row;
    position: relative;
    gap: 40px;
  }
}

.rioContainer.large .sentences {
  max-height: 70vh;
  overflow-y: scroll;
  flex: 0.7;
  align-self: unset;
  justify-content: flex-start;
  gap: min(calc(15px + 2vw), 20px);
}

.rioContainer.large:after {
  content: "";
  height: 15%;
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.rioContainer.large .sequences {
}

.rioContainer.large .sequences .word {
  padding: 4px 6px;
  /* border: 1px solid #808080;
  border-radius: 10px; */
  font-size: 24px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}
.rioContainer.large .sequences .word.readAnimate,
.word.readAnimate {
  /* animation: rotationaryShake 0.07s;
  animation-iteration-count: 15; */
  /* border: 1px solid rgba(38, 100, 184, 0.89) !important; */
  color: rgba(38, 100, 184, 0.89) !important;
}
@keyframes rotationaryShake {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(-7deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(7deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.sequences .word.correct,
.rioContainer.large .sequences .word.correct {
  /* border: 1px solid #16a085 !important; */
  color: #16a085 !important;
}
.sequences .word.error,
.rioContainer.large .sequences .word.error {
  /* border: 1px solid #af4154 !important; */
  color: #af4154 !important;
}

.sentenceExampleContainer {
  flex: 1;
  padding: 0 20%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: scroll;
}

.sentenceExampleContainer p {
  font-size: 28px;
  font-family: "League Spartan", Inter;
}

.buttonAndTimer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  align-self: stretch;
  gap: 20px;
}

.buttonAndTimer .timer {
  font-family: "League Spartan", Inter;
  font-weight: 500;
  font-size: 28px;
}

.contentContainer {
  padding: 0 30%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  gap: 15px;
}

.nextPrompt {
  text-align: center;
  align-self: center;
  font-family: "League Spartan", Inter;
  font-weight: 900;
  color: #2664b8;
}
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.bubble {
  animation: pulse 2s infinite;
}

@media (max-height: 1366px) {
  .primitive {
    scale: 1;
  }
}
@media (max-height: 900px) {
  .primitive {
    scale: 0.8;
  }
}

@media (max-height: 800px) {
  .primitive {
    scale: 0.6;
  }
}

.successPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1;

  gap: 30px;
}
.successPage.colored {
  background-color: #16a085;
}

.successPage * {
  font-family: "League Spartan", Inter;
}

.successPage h1 {
  color: #fff;
  font-size: calc(30px + 3vw);
  margin: 0;
}
.successPage span {
  color: #fff;
  font-size: 20px;
  max-width: 500px;
  text-align: center;
  margin: 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

@media (max-width: 500px) {
  .buttonsContainer {
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    padding: 0 40px;
    gap: 0px;
  }
}

.verticalButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.successPage button {
  margin-top: 20px;
  background: #fff !important;
  padding: 10px 50px;
  border: 2px solid #fff;
  font-weight: bold;
  color: #16a085;
  font-size: 20px;
  border-radius: 90px;
  cursor: pointer;
  margin-right: 15px;
}

.failButMovePage {
  background-color: #a0a0a0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1;
  gap: 30px;
}

.failPage {
  background-color: #af4154;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  flex: 1;
  gap: 30px;
}

.failPage * {
  font-family: "League Spartan", Inter;
}
.failPage img {
  width: 200px;
  height: 200px;
  object-fit: contain;
}
.failPage h1 {
  color: #fff;
  font-size: 52px;
  margin: 0;
}
.failPage span {
  color: #fff;
  font-size: 20px;
  max-width: 500px;
  text-align: center;
  margin: 0;
}
.failPage button {
  margin-top: 20px;
  background: #fff !important;
  padding: 10px 50px;
  border: 2px solid #fff;
  color: #af4154;
  font-size: 20px;
  border-radius: 90px;
  font-weight: bold;
  cursor: pointer;
}

.failPage svg,
.successPage svg {
  cursor: pointer;
  transition: 0.5s all;
}

.failPage svg:active,
.successPage svg:active {
  transform: scale(0.9);
}

.btnCta {
  background-color: #b59410;
  padding: 10px 24px;
  margin-top: 20px;
  border-radius: 25px;
  transition: all 0.4s ease-in-out;
  color: white;
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.btnCta:hover {
  gap: 15px;
  background-color: rgb(215, 136, 24);
}

@keyframes background-pan {
  from {
    background-position: 0% center;
  }

  to {
    background-position: -200% center;
  }
}

.magicText {
  --orange: rgb(255, 238, 51);
  --pink: rgba(206, 47, 255, 0.775);
  --violet: rgb(38, 52, 254);
  animation: background-pan 3s linear infinite;
  background: linear-gradient(
    to right,
    var(--orange),
    var(--pink),
    var(--violet),
    var(--orange)
  );
  font-size: 120px;
  background-size: 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
}



.container.normal{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
}

.container.half{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  flex: 1;
  align-self: stretch;
  overflow: hidden;
  position: relative;
}

.left {
  flex: 1;
  background-color: #fff;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}

.left span {
  font-size:var(--word-size-small);
  color: var(--hexcolor);
  font-weight: bolder;
  text-align: center;
}


.top {
  flex: 1;

  align-self: stretch;

  display: flex;
  align-items: center;
  justify-content: center;
}

.top span {
  font-size:var(--word-size);
  color: #fff;
  font-weight: bolder;

}
.popup {
  font-size:var(--font-smsize);
  font-weight: bolder;

}


@keyframes showAnimationwScale {
  0% {
    scale: 0.5;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

@keyframes hideAnimationwScale {
  0% {
    scale: 1;
    opacity: 1;
  }
  100% {

    scale: 0.5;
    opacity: 0;
  }
}


.right {
  flex: 1;
  position: relative;
  align-self: stretch;

  display: flex;
  align-items: center;
  justify-content: center;
}

.container:not(.half) .right span {
  font-size:var(--word-size);
  color: #fff;
  font-weight: bolder;

}

.container.half .top span {
  font-size:var(--centered-word-size-xl);
  color: #fff;
  font-weight: bolder;

}

@media (max-width: 900px) {
  .container{
    flex-direction: column!important;
  }

}


.container:not(.performing) .left span, .container:not(.performing) .right span, .container:not(.performing) .top span{
  animation: scalePump 1s alternate-reverse infinite;
}

.container:not(.isPageChanging) .left span,.container:not(.isPageChanging) .top span, .container:not(.isPageChanging) .right span {
  animation: showAnimationwScale 0.5s forwards;
}
.container.isPageChanging .left span, .container.isPageChanging .top span,.container.isPageChanging .right span {
  animation: hideAnimationwScale 0.5s forwards;
}

.actionIndicator {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #000;
  width: 120px;
  height: 120px;
  position: absolute;
  background-color: var(--hexcolor);
  border: 4px solid #fff;
  padding: 25px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 90px;
  max-width: 0px;
  max-height: 0px;
  opacity: 0;
  transition: 0.5s all;
}

.actionIndicator svg {
  color:  #fff;
}
.container.performing .actionIndicator {
  max-width: 120px;
  max-height: 120px;
  opacity: 1;
}

@keyframes scalePump {
  0% {
      opacity: 0.95;
      transform: scale(0.97);
  }
  100% {
      opacity: 1;
      transform: scale(1.01);
  }
}

.informingSilence {
  animation: colorAnimate 0.5s alternate-reverse infinite;
}

@keyframes colorAnimate {

  0%{
    --hexcolor: #fcd670;
  }

  100%{
    --hexcolor: #FF160C;
  }

}